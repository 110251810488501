@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chakra-tabs__tab {
  font-weight: bold;
}

.chakra-tabs__tab[aria-selected="true"] {
  background-color: white;
  border-bottom: 2px solid #3182ce;
}

.chakra-tabs__tab-panel {
  padding: 0;
}

.inventory-container .chakra-tabs__tab {
  font-weight: bold;
  border-radius: 0.375rem 0.375rem 0 0;
  margin-right: 0.25rem;
  padding: 0.75rem 1.5rem;
  background-color: #f3f4f6;
  transition: background-color 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.inventory-container .chakra-tabs__tab[aria-selected="true"] {
  background-color: white;
  color: #3182ce;
  border-bottom: 2px solid #3182ce;
}

.inventory-container .chakra-tabs__tab:hover {
  background-color: #e5e7eb;
}

/* Add these new styles */
.inventory-container .chakra-tabs__tablist {
  background-color: #f3f4f6;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  padding: 0.5rem 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.inventory-container .chakra-tabs__tab-panel {
  background-color: white;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 1rem;
}

.chakra-button {
  text-transform: uppercase;
  font-weight: bold;
}

.history-container {
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-top: 1rem;
  padding: 1rem;
}

.history-container .chakra-tabs {
  background-color: transparent;
}

.history-container .chakra-tabs__tablist {
  background-color: transparent;
  padding: 0;
  margin-left: -1px;
}

.history-container .chakra-tabs__tab {
  background-color: #f3f4f6;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  margin-right: 0.25rem;
  border: 1px solid #e5e7eb;
  border-bottom: none;
  padding: 0.75rem 1.5rem;
}

.history-container .chakra-tabs__tab:first-of-type {
  margin-left: 1px;
}

.history-container .chakra-tabs__tab[aria-selected="true"] {
  background-color: white;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
}

.history-container .chakra-tabs__tab-panel {
  padding: 0;
}

.reagent-table {
  width: 100%;
  border-collapse: collapse;
}

.reagent-table th,
.reagent-table td {
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.reagent-table th {
  font-weight: bold;
  background-color: #f7fafc;
}

.reagent-table tr:nth-of-type(even) {
  background-color: #f7fafc;
}

.overdue-reagent {
  background-color: #FED7D7; /* Light red background */
}

.overdue-text {
  color: #E53E3E; /* Red text */
  font-weight: bold;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
}

.history-table th {
  font-weight: bold;
  background-color: #f7fafc;
}

.history-table tr {
  display: flex;
  width: 100%;
}

.history-table th,
.history-table td {
  flex: 1;
  display: flex;
  align-items: center;
}

.history-table th:nth-child(1),
.history-table td:nth-child(1) { flex: 1; }
.history-table th:nth-child(2),
.history-table td:nth-child(2) { flex: 1; }
.history-table th:nth-child(3),
.history-table td:nth-child(3) { flex: 2; }

.history-table td:nth-child(2) {
  display: flex;
  align-items: center;
}

.history-table .user-profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  flex-shrink: 0;
}

.history-table .user-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .inventory-container .chakra-tabs__tablist {
    flex-direction: column;
  }

  .inventory-container .chakra-tabs__tab {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.25rem;
  }

  .reagent-table th,
  .reagent-table td {
    padding: 8px;
  }

  .reagent-table th:nth-child(4),
  .reagent-table td:nth-child(4),
  .reagent-table th:nth-child(5),
  .reagent-table td:nth-child(5) {
    display: none;
  }

  .history-table tr {
    flex-direction: column;
  }

  .history-table th,
  .history-table td {
    width: 100%;
    text-align: left;
  }

  .history-table th:not(:first-child),
  .history-table td:not(:first-child) {
    padding-left: 1rem;
  }
}

/* Mobile Navigation Styles */
.mobile-nav {
  background-color: #2d3748 !important; /* Match the sidebar background color and use !important to ensure it is applied */
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 60px; /* Ensure the bottom bar has the correct height */
}

.mobile-nav .chakra-icon-button {
  transition: color 0.3s ease, transform 0.3s ease;
  color: white; /* Make the icons white by default */
}

.mobile-nav .chakra-icon-button:hover {
  color: #3182ce; /* Hover color */
  transform: scale(1.2);
}

.mobile-nav .chakra-icon-button:active {
  transform: scale(1.4); /* Increase the size of the icons when pressed */
}

.mobile-nav .chakra-icon-button[aria-current="page"] {
  color: #3182ce; /* Active color */
}

/* Mobile Tabs Styles */
.mobile-tabs .chakra-tabs__tablist {
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-bottom: 1px solid #e2e8f0; /* Add bottom border to tab list */
  padding: 0.5rem;
}

.mobile-tabs .chakra-tabs__tab {
  font-weight: bold;
  border-radius: 0.375rem 0.375rem 0 0; /* Only round top corners */
  margin-right: 0.25rem;
  padding: 0.75rem 1.5rem;
  background-color: white; /* Match the tab list background color */
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent; /* Add bottom border to tabs */
}

.mobile-tabs .chakra-tabs__tab[aria-selected="true"] {
  background-color: white;
  color: #3182ce;
  border-bottom: 2px solid #3182ce; /* Highlight the selected tab */
}

.mobile-tabs .chakra-tabs__tab:hover {
  background-color: #f3f4f6;
}

/* Mobile Full History Styles */
@media screen and (max-width: 768px) {
  .full-history-mobile {
    background-color: #f7fafc;
  }

  .full-history-mobile .chakra-tabs__tablist {
    background-color: #2d3748;
    border-bottom: none;
    padding: 0.5rem;
  }

  .full-history-mobile .chakra-tabs__tab {
    color: white;
    background-color: transparent;
    border-bottom: none;
    font-weight: bold;
    padding: 0.75rem 1rem;
  }

  .full-history-mobile .chakra-tabs__tab[aria-selected="true"] {
    background-color: #3182ce;
    color: white;
    border-radius: 0.375rem;
  }

  .full-history-mobile .chakra-tabs__tab-panel {
    background-color: white;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .full-history-mobile .swanky-input,
  .full-history-mobile .swanky-select,
  .full-history-mobile .swanky-datepicker {
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
  }

  .full-history-mobile .entry-box {
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
  }

  .full-history-mobile .entry-title {
    color: #2d3748;
  }

  .full-history-mobile .entry-details {
    color: #4a5568;
  }

  .full-history-mobile .swanky-button {
    background: #3182ce;
    color: white;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }
}

/* Profile Image Styles */
.profile-image {
  width: 64px;  /* Default size, can be overridden */
  height: 64px; /* Default size, can be overridden */
  border-radius: 50%;
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.user-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar-profile-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar-user-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar-user-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Entry Styles */
.entry-box {
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.entry-box .entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.entry-box .entry-header .entry-title {
  font-weight: bold;
}

.entry-box .entry-header .entry-status {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.entry-box .entry-details {
  font-size: 0.875rem;
  color: #4a5568;
}

.entry-box .entry-details .entry-icon {
  margin-right: 0.5rem;
}

.entry-box .entry-details .entry-time-left {
  margin-top: 0.5rem;
}

.entry-box .entry-details .entry-user {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.entry-box .entry-details .entry-user img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

/* Swanky Styles */
.swanky-container {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: box-shadow 0.3s ease;
}

.swanky-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.swanky-title {
  color: #2d3748;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.swanky-label {
  color: #4a5568;
}

.swanky-input {
  border: 1px solid #cbd5e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.swanky-input:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.swanky-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  transition: background 0.3s ease, transform 0.3s ease;
}

.swanky-button:hover {
  background: linear-gradient(135deg, #5a67d8 0%, #6b46c1 100%);
  transform: translateY(-2px);
}

.swanky-button:active {
  transform: translateY(0);
}

.swanky-image {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.swanky-image:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.swanky-preview-container {
  width: 200px;
  height: 200px;
  border: 1px solid #cbd5e0;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swanky-preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swanky-message {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.swanky-tab {
  font-weight: bold;
  border-radius: 0.375rem 0.375rem 0 0; /* Only round top corners */
  margin-right: 0.25rem;
  padding: 0.75rem 1.5rem;
  background-color: white; /* Match the tab list background color */
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent; /* Add bottom border to tabs */
}

.swanky-tab[aria-selected="true"] {
  background-color: white;
  color: #3182ce;
  border-bottom: 2px solid #3182ce; /* Highlight the selected tab */
}

.swanky-tab:hover {
  background-color: #f3f4f6;
}

.swanky-select {
  border: 1px solid #cbd5e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.swanky-select:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.swanky-datepicker {
  border: 1px solid #cbd5e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.swanky-datepicker:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Flashing Animation */
@keyframes flash {
  0% { background-color: #ffcccc; }
  50% { background-color: #ff6666; }
  100% { background-color: #ffcccc; }
}

.flashing {
  animation: flash 2s infinite;
}